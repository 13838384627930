import request from '@/utils/request';

// 重置密码
export function resetPsw(data) {
  return request({
    data,
    url: '/admin/reset-password',
    method: 'post',
  });
}

// 删除
export function del(id) {
  return request({
    url: `/admin/delete?ids=${id}`,
    method: 'delete',
  });
}

// 修改/新增
export function save(data) {
  return request({
    data,
    url: '/admin/save',
    method: 'post',
  });
}
